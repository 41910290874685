import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppDispatch } from 'setup/redux/Hooks';
import * as auth from './redux/AuthSlice';

export function Logout() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(auth.logout());
    document.location.reload();
  }, [dispatch]);

  return <Navigate to="/auth/login" />;
}
