import { UserModel } from 'app/models/user/UserModel';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IAuthState {
  user?: UserModel;
  authToken?: string;
}

const initialState: IAuthState = {
  user: undefined,
  authToken: undefined,
};

// this uses the redux toolkit slice pattern,
// this allows us to mutate the state directly without returning a copy
// that's why there is no need to return anything with these reducers

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login(state, action: PayloadAction<string>) {
      state.authToken = action.payload;
      state.user = undefined;
    },

    register(state, action: PayloadAction<string>) {
      state.authToken = action.payload;
      state.user = undefined;
    },

    logout(state) {
      state.authToken = undefined;
      state.user = undefined;
    },

    userRequested(state) {
      state.user = undefined;
    },

    userLoaded(state, action: PayloadAction<UserModel>) {
      state.user = action.payload;
    },

    setUser(state, action: PayloadAction<UserModel>) {
      state.user = action.payload;
    },
  },
});

export const { login, register, logout, userRequested, setUser } = authSlice.actions;
export const { reducer: authReducer } = authSlice;
