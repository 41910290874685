/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react';
import { shallowEqual } from 'react-redux';
import { Link } from 'react-router-dom';
import { UserModel } from 'app/models/user/UserModel';
import { Languages } from './Languages';
import * as auth from 'app/modules/auth/redux/AuthSlice';
import { toAbsoluteUrl } from '../../../helpers';
import { useAppDispatch, useAppSelector } from 'setup/redux/Hooks';

const HeaderUserMenu: FC = () => {
  const user: UserModel = useAppSelector(({ auth }) => auth.user, shallowEqual) as UserModel;

  const dispatch = useAppDispatch();
  const logout = () => {
    dispatch(auth.logout());
  };

  return (
    <div
      className="py-4 menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold fs-6 w-275px"
      data-kt-menu="true"
    >
      <div className="px-3 menu-item">
        <div className="px-3 menu-content d-flex align-items-center">
          <div className="symbol symbol-50px me-5">
            <img alt="Logo" src={toAbsoluteUrl('/media/avatars/150-26.jpg')} />
          </div>

          <div className="d-flex flex-column">
            <div className="fw-bolder d-flex align-items-center fs-5">
              {user.firstname} {user.firstname}
              <span className="px-2 py-1 badge badge-light-success fw-bolder fs-8 ms-2">Pro</span>
            </div>
            <a href="#" className="fw-bold text-muted text-hover-primary fs-7">
              {user.email}
            </a>
          </div>
        </div>
      </div>

      <div className="my-2 separator"></div>

      <div className="px-5 menu-item">
        <Link to={'/crafted/pages/profile'} className="px-5 menu-link">
          My Profile
        </Link>
      </div>

      <div className="px-5 menu-item">
        <a href="#" className="px-5 menu-link">
          <span className="menu-text">My Projects</span>
          <span className="menu-badge">
            <span className="badge badge-light-danger badge-circle fw-bolder fs-7">3</span>
          </span>
        </a>
      </div>

      <div
        className="px-5 menu-item"
        data-kt-menu-trigger="hover"
        data-kt-menu-placement="left-start"
        data-kt-menu-flip="bottom"
      >
        <a href="#" className="px-5 menu-link">
          <span className="menu-title">My Subscription</span>
          <span className="menu-arrow"></span>
        </a>

        <div className="py-4 menu-sub menu-sub-dropdown w-175px">
          <div className="px-3 menu-item">
            <a href="#" className="px-5 menu-link">
              Referrals
            </a>
          </div>

          <div className="px-3 menu-item">
            <a href="#" className="px-5 menu-link">
              Billing
            </a>
          </div>

          <div className="px-3 menu-item">
            <a href="#" className="px-5 menu-link">
              Payments
            </a>
          </div>

          <div className="px-3 menu-item">
            <a href="#" className="px-5 menu-link d-flex flex-stack">
              Statements
              <i
                className="fas fa-exclamation-circle ms-2 fs-7"
                data-bs-toggle="tooltip"
                title="View your statements"
              />
            </a>
          </div>

          <div className="my-2 separator"></div>

          <div className="px-3 menu-item">
            <div className="px-3 menu-content">
              <label className="form-check form-switch form-check-custom form-check-solid">
                <input
                  className="form-check-input w-30px h-20px"
                  type="checkbox"
                  value="1"
                  defaultChecked={true}
                  name="notifications"
                />
                <span className="form-check-label text-muted fs-7">Notifications</span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div className="px-5 menu-item">
        <a href="#" className="px-5 menu-link">
          My Statements
        </a>
      </div>

      <div className="my-2 separator"></div>

      <Languages />

      <div className="px-5 my-1 menu-item">
        <Link to="/crafted/account/settings" className="px-5 menu-link">
          Account Settings
        </Link>
      </div>

      <div className="px-5 menu-item">
        <a onClick={logout} className="px-5 menu-link">
          Sign Out
        </a>
      </div>
    </div>
  );
};

export { HeaderUserMenu };
