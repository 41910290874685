import { combineReducers } from 'redux';

import { authReducer } from 'app/modules/auth/redux/AuthSlice';
import { ticketReducer } from 'app/modules/tickets/redux/TicketSlice';
import { baseApi } from './apis/Base';

export const rootReducer = combineReducers({
  auth: authReducer,
  ticket: ticketReducer,
  [baseApi.reducerPath]: baseApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
