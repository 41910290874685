import { AuthModel } from 'app/models/auth/AuthModel';
import { UserModel } from 'app/models/user/UserModel';
import { baseApi } from '.';

const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    register: builder.mutation<
      AuthModel,
      {
        email: string;
        password: string;
        fullname: string;
        username: string;
      }
    >({
      query: (arg) => ({
        method: 'POST',
        body: arg,
        url: '/admin/register',
      }),
    }),

    requestPassword: builder.mutation<{ result: boolean }, { email: string }>({
      query: (arg) => ({ method: 'POST', url: '/admin/forgot-password', body: arg }),
    }),

    getUserByToken: builder.query<UserModel, void>({
      query: () => '/admin/me',
    }),

    login: builder.mutation<AuthModel, { email: string; password: string }>({
      query: (arg) => ({
        method: 'POST',
        body: arg,
        url: '/admin/login',
      }),

      transformResponse: (res: { authToken: string }) => ({
        authToken: res.authToken,
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRegisterMutation,
  useRequestPasswordMutation,
  useGetUserByTokenQuery,
} = authApi;
