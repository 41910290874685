import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../RootReducer';

export const baseApi = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_URL}/api`,
    prepareHeaders(headers, { getState }) {
      const token = (getState() as RootState).auth.authToken;
      if (token) {
        headers.set('Authorization', `${token}`);
      }
      return headers;
    },
  }),

  endpoints(builder) {
    // so this is where you set up all the api endpoints that you will end up using. works like react fetch by just calling useFetchCustomersQuery()
    return {
      // FIXME the params here are not correct, fix when the full schema is available
    };
  },
});
