import { FC, useEffect, useState } from 'react';
import { Table } from 'react-bootstrap-v5';

interface Props<T extends Record<string, number | string>> {
  columns: CreateDataTableColumns<T>;
  rows: Row<T>[];
  order: Array<keyof T>;

  onRowClick?: (row: Row<T>) => void;
}

type Row<T extends Record<string, number | string>> = {
  [P in keyof T]: T[P];
};

type CreateDataTableColumns<T> = Record<keyof T, DataTableColumn>;

interface DataTableColumn {
  sort?: boolean;
  label: string;
}

export const DataTable = <T extends Record<string, number | string>>({
  columns,
  rows,
  order,
  onRowClick,
}: Props<T>) => {
  const [r, setR] = useState(rows);
  const [sort, setSort] = useState<{ col: keyof T; direction: 'ASC' | 'DSC' } | undefined>();

  // useEffect(() => {
  //   if (sort) {
  //     const newRows = [...r];

  //     newRows.sort((a, b) => {
  //       return a[sort.col] > b[sort.col] ? 1 : -1;
  //     });

  //     if (sort.direction === 'DSC') newRows.reverse();

  //     setR(newRows);
  //   } else {
  //     setR(rows);
  //   }
  // }, [sort, rows]);

  const handleSort = (col: keyof T, direction: 'ASC' | 'DSC') => {
    if (col === sort?.col && direction === sort?.direction) {
      setSort({ col, direction });

      const newRows = [...r];

      newRows.sort((a, b) => (a[col] > b[col] ? 1 : -1));

      if (direction === 'ASC') newRows.reverse();

      setR(newRows);
    } else {
      setSort(undefined);
      setR(r);
    }
  };

  return (
    <Table striped bordered hover>
      <thead>
        <tr>
          {order.map((e) => (
            <th>
              {columns[e].label}

              {columns[e].sort && (
                <>
                  <i className="bi bi-caret-down" />
                  <i className="bi bi-caret-up" />
                </>
              )}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {r.map((e) => (
          <tr onClick={onRowClick ? () => onRowClick(e) : undefined}>
            {order.map((b) => (
              <td>{e[b]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

/* <DataTable
  columns={{ a: { label: 'A', sort: true, type: 'string' } }}
  rows={[{ a: 9 }]}
  order={['a']}
/>; */
