import { TicketMergedReply } from 'app/models/tickets/TicketMergedReply';
import { TicketModel } from 'app/models/tickets/TicketModel';
import { baseApi } from '.';

const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    allTickets: builder.query<TicketModel[], { limit?: number; page?: number }>({
      query: ({ limit = 50, page = 0 }) => ({
        method: 'GET',
        url: '/admin/getticketallticket',
        params: {
          page,
          limit,
        },
      }),

      transformResponse: (res: any) => [...res.data],
    }),

    ticketReplies: builder.query<TicketMergedReply, { ticketid: number }>({
      query: ({ ticketid }) => ({
        method: 'POST',
        url: '/admin/getticketreplies',
        body: { ticketid },
      }),

      transformResponse: (res: any) => {
        console.log(res);
        return { replies: res.data, ticket: res.ticket };
      },
    }),

    ticketReply: builder.mutation<void, { ticketid: number; answer: string }>({
      query: (params) => ({
        method: 'POST',
        url: '/admin/replyonticketbycustomer',
        body: params,
      }),
    }),
  }),
});

export const { useAllTicketsQuery, useTicketRepliesQuery, useTicketReplyMutation } = authApi;
