import TicketPage from 'app/modules/tickets/TicketPage';
import { Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { FallbackView } from '_metronic/partials';
import { DashboardWrapper } from 'app/pages/dashboard/DashboardWrapper';
import { MenuTestPage } from 'app/pages/MenuTestPage';

export function PrivateRoutes() {
  const BuilderPageWrapper = lazy(() => import('app/pages/layout-builder/BuilderPageWrapper'));
  const ProfilePage = lazy(() => import('app/modules/profile/ProfilePage'));
  const WizardsPage = lazy(() => import('app/modules/wizards/WizardsPage'));
  const AccountPage = lazy(() => import('app/modules/accounts/AccountPage'));
  const WidgetsPage = lazy(() => import('app/modules/widgets/WidgetsPage'));
  const ChatPage = lazy(() => import('app/modules/apps/chat/ChatPage'));
  const CustomerPage = lazy(() => import('app/modules/customer/CustomerPage'));

  console.log('hello');

  return (
    <Suspense fallback={<FallbackView />}>
      <Routes>
        <Route path="dashboard/*" element={<DashboardWrapper />} />
        <Route path="builder/*" element={<BuilderPageWrapper />} />
        <Route path="crafted/pages/profile/*" element={<ProfilePage />} />
        <Route path="crafted/pages/wizards/*" element={<WizardsPage />} />
        <Route path="crafted/widgets/*" element={<WidgetsPage />} />
        <Route path="crafted/account/*" element={<AccountPage />} />
        <Route path="apps/chat/*" element={<ChatPage />} />
        <Route path="menu-test/*" element={<MenuTestPage />} />
        <Route path="customers/*" element={<CustomerPage />} />
        <Route path="tickets/*" element={<TicketPage />} />
        <Route path="auth/*" element={<Navigate to="/dashboard" />} />
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="/*" element={<Navigate to="error/404" />} />
      </Routes>
    </Suspense>
  );
}
