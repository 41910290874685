/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * elements (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { shallowEqual } from 'react-redux';
import { MasterLayout } from '_metronic/layout/MasterLayout';
import { PrivateRoutes } from './PrivateRoutes';
import { AuthPage } from 'app/modules/auth/AuthPage';
import { Logout } from 'app/modules/auth/Logout';
import { ErrorsPage } from 'app/modules/errors/ErrorsPage';
import { MasterInit } from '_metronic/layout/MasterInit';
import { useAppSelector } from 'setup/redux/Hooks';

const Router: FC = () => {
  const isAuthorized = useAppSelector(({ auth }) => auth.user, shallowEqual);
  console.log('ello');
  return (
    <>
      <Routes>
        {!isAuthorized ? (
          <Route path="auth/*" element={<AuthPage />} />
        ) : (
          // <Redirect from="/auth" to="/" />
          <Route path="auth/*" element={<Navigate to="/" />} />
          // < path="/auth" element={}>
        )}

        <Route path="error/*" element={<ErrorsPage />} />
        <Route path="logout/*" element={<Logout />} />

        {!isAuthorized ? (
          <Route path="/*" element={<Navigate to="/auth/login" />} />
        ) : (
          <Route
            path="/*"
            element={
              <MasterLayout>
                <PrivateRoutes />
              </MasterLayout>
            }
          />
        )}
      </Routes>
      <MasterInit />
    </>
  );
};

export { Router };
