import { configureStore } from '@reduxjs/toolkit';
// import createSagaMiddleware from 'redux-saga';
import { reduxBatch } from '@manaflair/redux-batch';
import { persistReducer, persistStore } from 'redux-persist';
// import { rootReducer, rootSaga } from './RootReducer';
import { rootReducer } from './RootReducer';
import { baseApi } from './apis/Base';
import storage from 'redux-persist/lib/storage';

// const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: persistReducer({ key: 'root', storage }, rootReducer),
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
      thunk: true,
    }).concat(baseApi.middleware);
    // .concat(sagaMiddleware);
  },
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: [reduxBatch],
});

export type AppDispatch = typeof store.dispatch;

/**
 * @see https://github.com/rt2zz/redux-persist#persiststorestore-config-callback
 * @see https://github.com/rt2zz/redux-persist#persistor-object
 */
export const persistor = persistStore(store);

// sagaMiddleware.run(rootSaga);

export default store;
