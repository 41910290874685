/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router-dom';
import { Error500 } from './components/Error500';
import { Error404 } from './components/Error404';
import { toAbsoluteUrl } from '_metronic/helpers';

const ErrorsPage: React.FC = () => {
  console.log('error page');
  const history = useNavigate();
  const redirectToDashboard = () => {
    history('/');
  };

  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
        style={{
          backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/progress-hd.png')}')`,
        }}
      >
        <div className="p-10 text-center d-flex flex-column flex-column-fluid py-lg-20">
          <a href="/dashboard" className="mb-10 pt-lg-20">
            <img
              alt="Logo"
              src={toAbsoluteUrl('/media/logos/logo-1.svg')}
              className="mb-5 h-50px"
            />
          </a>
          <div className="mb-10 pt-lg-10">
            <Routes>
              <Route path="404" element={<Error404 />}></Route>
              <Route path="/500" element={<Error500 />}></Route>
              <Route index element={<Navigate to="/error/404" />} />
            </Routes>

            <div className="text-center">
              <a onClick={redirectToDashboard} className="btn btn-lg btn-primary fw-bolder">
                Go to homepage
              </a>
            </div>
          </div>
          <div
            className=" d-flex flex-row-auto bgi-no-repeat bgi-position-x-center bgi-size-contain bgi-position-y-bottom min-h-100px min-h-lg-350px"
            style={{
              backgroundImage: `url('${toAbsoluteUrl('/media/illustrations/sketchy-1/17.png')}')`,
            }}
          ></div>
        </div>

        <div className="p-10 d-flex flex-center flex-column-auto">
          <div className="d-flex align-items-center fw-bold fs-6">
            <a href="https://keenthemes.com" className="px-2 text-muted text-hover-primary">
              About
            </a>
            <a href="mailto:support@keenthemes.com" className="px-2 text-muted text-hover-primary">
              Contact
            </a>
            <a href="https://1.envato.market/EA4JP" className="px-2 text-muted text-hover-primary">
              Contact Us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export { ErrorsPage };
