import { FC, useRef, useState, useEffect } from 'react';
import { LayoutSplashScreen } from '_metronic/layout/core';
import * as auth from 'app/modules/auth/redux/AuthSlice';
import { useGetUserByTokenQuery } from 'setup/redux/apis/';
import { useAppDispatch, useAppSelector } from 'setup/redux/Hooks';

const AuthInit: FC = (props) => {
  const dispatch = useAppDispatch();
  const fetched = useRef(false);
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const accessToken = useAppSelector((state) => state.auth.authToken);
  const { data: user, refetch } = useGetUserByTokenQuery();

  useEffect(() => {
    refetch();
    fetched.current = true;
  }, [accessToken, refetch]);

  useEffect(() => {
    if (accessToken && fetched && user) {
      dispatch(auth.setUser(user));
      setShowSplashScreen(false);
    } else if (fetched && !accessToken) {
      dispatch(auth.logout());
      setShowSplashScreen(false);
    }

    fetched.current = false;
  }, [user, accessToken, dispatch, fetched]);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>;
};

export default AuthInit;
