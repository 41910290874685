export interface TicketModel {
  id: number;
  ticketno: string;
  title: string;
  customerid: number;
  ticketdate: string;
  status: number;
  reopen_count: number;
  created_by_user_type: number;
  created_by_user_id: number;
  createdAt: number;
  updatedAt: number;
  created_at: string;
  customername: string;
  customerinfo: Customerinfo;
}

export enum TicketStatus {
  'New Ticket' = 0,
  'Replied' = 1,
  'Customer Replied' = 2,
  Closed,
}

interface Customerinfo {
  id: number;
  cleaner_id: number;
  distributorid: number;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  gender: string;
  flatno: string;
  locality: string;
  address_line_1: string;
  address_line_2: string;
  latitude: number;
  longitude: number;
  city: number;
  state: string;
  pincode: string;
  is_premium: number;
  termscondi: number;
  status: number;
  profile_image: string;
  stage: number;
  societyid: number;
  cleanercode: string;
  cleanerlead: number;
  is_autodebit_enable: number;
  userType: number;
  auto_debit_access_key: null;
  customer_authentication_id: null;
  authorization_status: null;
  coins: number;
  razorpay_customer_id: string;
  referBy: string;
  app_rating: number;
  is_deleted: number;
  createdAt: number;
  updatedAt: number;
}
