/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC } from 'react';
import { Button, Modal } from 'react-bootstrap-v5';
import { useNavigate } from 'react-router-dom';

import { useParams } from 'react-router-dom';
import { useAllTicketsQuery, useTicketRepliesQuery } from 'setup/redux/apis';
import { TicketInnerChat } from './TicketInnerChat';

const TicketChatModal: FC = () => {
  const { ticketId } = useParams();
  const navigate = useNavigate();

  const { data } = useTicketRepliesQuery({ ticketid: Number(ticketId) });
  const { data: tickets } = useAllTicketsQuery({});

  const handleNextTicket = () => {
    const map = tickets?.map((ticket) => ticket.id);

    if (map) {
      navigate(`/tickets/${map[map.indexOf(data?.ticket.id ?? 0) + 1]}`);
    }
  };

  const handlePreviousTicket = () => {
    const map = tickets?.map((ticket) => ticket.id);

    if (map) {
      navigate(`/tickets/${map[map.indexOf(data?.ticket.id ?? 5) - 1]}`);
    }
  };

  return (
    <Modal show={true} onHide={() => navigate('/tickets')}>
      <Modal.Header closeButton>
        <Modal.Title>{data?.ticket.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TicketInnerChat ticketId={Number(ticketId)} />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handlePreviousTicket}>
          <i className="bi bi-arrow-left" />
          Previous
        </Button>
        <Button variant="secondary" onClick={handleNextTicket}>
          Next <i className="bi bi-arrow-right" />
        </Button>
        <Button variant="secondary" onClick={() => navigate('/tickets')}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export { TicketChatModal };
