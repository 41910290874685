/* eslint-disable jsx-a11y/anchor-is-valid */
import { TicketStatus } from 'app/models/tickets/TicketModel';
import { DataTable } from 'app/modules/tables/DataTable';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAllTicketsQuery } from 'setup/redux/apis';
import { useAppDispatch, useAppSelector } from 'setup/redux/Hooks';
import { KTSVG } from '_metronic/helpers';
import { changePage } from '../redux/TicketSlice';

type TableFields = {
  id: number;
  customerName: string;
  customerPhone: string;
  status: string;
  title: string;
};

type Props = {
  className: string;
};

const TicketTable: React.FC<Props> = ({ className }) => {
  const navigate = useNavigate();
  const page = useAppSelector(({ ticket }) => ticket.page);
  const dispatch = useAppDispatch();
  const { data: tickets, refetch } = useAllTicketsQuery({ limit: 50, page });
  const morePages = useMemo(() => tickets?.length === 50, [tickets]);

  const previousPage = () => {
    if (page !== 0) {
      dispatch(changePage(page - 1));
      refetch();
    } else alert('This is page zero, you cannot go back');
  };

  const nextPage = () => {
    if (morePages) {
      dispatch(changePage(page + 1));
      refetch();
    } else alert('There are no more tickets');
  };

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className="pt-5 border-0 card-header">
        <h3 className="card-title align-items-start flex-column">
          <span className="mb-1 card-label fw-bolder fs-3">Customer Tickets</span>
          <span className="mt-1 text-muted fw-bold fs-7">{tickets?.length} tickets</span>
        </h3>
        <div
          className="card-toolbar"
          data-bs-toggle="tooltip"
          data-bs-placement="top"
          data-bs-trigger="hover"
          title="Click to add a user"
        >
          <a
            href="#"
            className="btn btn-sm btn-light-primary"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_invite_friends"
          >
            <KTSVG path="media/icons/duotune/arrows/arr075.svg" className="svg-icon-3" />
            New Member
          </a>
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className="py-3 card-body">
        {/* begin::Table container */}
        {tickets && (
          <DataTable<TableFields>
            columns={{
              id: { label: 'ID', sort: true },
              customerName: { label: 'Customer Name', sort: true },
              customerPhone: { label: 'Customer Phone', sort: true },
              status: { label: 'Status', sort: true },
              title: { label: 'Title', sort: true },
            }}
            rows={tickets.map(({ id, customername, customerinfo, status, title }) => ({
              id,
              customerName: customername,
              customerPhone: customerinfo.phone,
              status:
                TicketStatus.Closed === status
                  ? 'Closed'
                  : TicketStatus.Replied === status
                  ? 'Replied'
                  : TicketStatus['New Ticket'] === status
                  ? 'New Ticket'
                  : 'Closed',
              title: title,
            }))}
            order={['id', 'customerName', 'customerPhone', 'status', 'title']}
            onRowClick={(ticket) => {
              navigate(`/tickets/${ticket.id}`);
            }}
          />
        )}
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  );
};

export { TicketTable };
