import { FC } from 'react';
import { Route, Routes } from 'react-router-dom';
import { TicketChatModal } from './components/TicketChatModal';
import { TicketHeader } from './TicketHeader';
const CustomerPage: FC = () => {
  return (
    <>
      <TicketHeader />
      <Routes>
        <Route path=":ticketId">
          <Route index element={<TicketChatModal />} />
        </Route>
      </Routes>
    </>
  );
};

export default CustomerPage;
