/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react';
import clsx from 'clsx';
import { useTicketRepliesQuery, useTicketReplyMutation } from 'setup/redux/apis';

type Props = {
  isDrawer?: boolean;
  ticketId: number;
};

const TicketInnerChat: FC<Props> = ({ isDrawer = false, ticketId }) => {
  const { data: messages } = useTicketRepliesQuery({ ticketid: ticketId });

  const [reply, setReply] = useState<string>('Testing');
  const [sendReply] = useTicketReplyMutation();

  console.log(messages);

  return (
    <div className="card-body">
      <div className={clsx('me-n5 pe-5', 'h-300px h-lg-auto', 'overflow-auto')}>
        {messages?.replies.map((m) => {
          const d = new Date(m.createdAt);
          return (
            <div
              key={m.id}
              className={clsx(
                'd-flex flex-column align-items',
                `align-items-${m.userid === 0 ? 'start' : 'end'}`
              )}
            >
              <div className="me-3">
                {/* <span className="text-muted fs-7 mb-1">{m.time}</span> */}
                <h1 className="m-1 text-secondary fs-6">
                  {m.userid !== 0 ? 'You' : 'Customer'} {d.toLocaleString()}
                </h1>
              </div>

              <div
                className={clsx(
                  'p-5 rounded',
                  `bg-light-${m.userid === 0 ? 'info' : 'primary'}`,
                  'text-dark fw-bold mw-lg-400px',
                  `text-${m.userid === 0 ? 'start' : 'end'}`
                )}
                data-kt-element="message-text"
              >
                {m.answers}
              </div>
            </div>
          );
        })}
      </div>

      <div
        className="card-footer pt-4 mt-3"
        id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
      >
        <textarea
          className="form-control form-control-flush mb-3 border rounded-2"
          rows={1}
          placeholder="Type a message"
          value={reply}
          onChange={(e) => setReply(e.target.value)}
        ></textarea>

        <div className="d-flex flex-stack">
          <button
            className="btn btn-primary"
            type="button"
            data-kt-element="send"
            onClick={() => sendReply({ ticketid: ticketId, answer: reply })}
          >
            Send
          </button>
        </div>
      </div>
    </div>
  );
};

export { TicketInnerChat };
