/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Registration } from './components/Registration';
import { ForgotPassword } from './components/ForgotPassword';
import { Login } from './components/Login';
import { toAbsoluteUrl } from '_metronic/helpers';

export function AuthPage() {
  console.log('AuthPage');
  useEffect(() => {
    document.body.classList.add('bg-white');
    return () => {
      document.body.classList.remove('bg-white');
    };
  }, []);

  return (
    <div
      className="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/14.png')})`,
      }}
    >
      {/* begin::Content */}
      <div className="p-10 d-flex flex-center flex-column flex-column-fluid pb-lg-20">
        {/* begin::Logo */}
        <a href="#" className="mb-12">
          <img alt="Logo" src={toAbsoluteUrl('/media/logos/logo-1.svg')} className="h-45px" />
        </a>
        {/* end::Logo */}
        {/* begin::Wrapper */}
        <div className="p-10 mx-auto bg-white rounded shadow-sm w-lg-500px p-lg-15">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/registration" element={<Registration />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route index element={<Navigate to="/auth/login" />} />
          </Routes>
        </div>
        {/* end::Wrapper */}
      </div>
      {/* end::Content */}
      {/* begin::Footer */}
      <div className="p-10 d-flex flex-center flex-column-auto">
        <div className="d-flex align-items-center fw-bold fs-6">
          <a href="#" className="px-2 text-muted text-hover-primary">
            About
          </a>

          <a href="#" className="px-2 text-muted text-hover-primary">
            Contact
          </a>

          <a href="#" className="px-2 text-muted text-hover-primary">
            Contact Us
          </a>
        </div>
      </div>
      {/* end::Footer */}
    </div>
  );
}
