import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ITicketState {
  page: number;
}

const initialState: ITicketState = {
  page: 0,
};

const ticketSlice = createSlice({
  name: 'ticket',
  initialState,
  reducers: {
    changePage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
  },
});

export const { changePage } = ticketSlice.actions;
export const { reducer: ticketReducer } = ticketSlice;
